import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Container } from 'reactstrap';
import Img from 'gatsby-image/withIEPolyfill';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

const BlogPost = styled(Container)`
`;

const Heading = styled.div`
  h1 {
    ${props => props.theme.main.fonts.displayLarge}
    margin-bottom: 0px;
  }
  h3 {
    ${props => props.theme.main.fonts.displaySmall}
    color: ${props => props.theme.main.colors.grey.darker};
    margin-bottom: 0;
  }
  .author {
    ${props => props.theme.main.fonts.body}
    color: ${props => props.theme.main.colors.grey.slate};
    font-weight: bold;
    margin-top: 32px;
  }
  .date {
    ${props => props.theme.main.fonts.body}
    color: ${props => props.theme.main.colors.grey.darker};
    margin-bottom: 24px;
  }
`;

const ImageBanner = styled.div`
  left: 0;
  right: 0;
  max-width: 100%;
`

const Image = styled.div`
  width: 70%;
  height: auto;
  left: 0px;
  right: 0px;
  margin: auto;
`

const TextSection = styled.div`
  padding-bottom: 32px;
  .gatsby-image-wrapper {
    ${breakpoint('md')`
      width: 60%;
    `}
  }
`

const Text = styled.div`
  margin-top: 36px;
  h2, h3, h4, h5, h6 {
    font-family: Libre Franklin;
  }
  p {
    ${props => props.theme.main.fonts.body}
  }
`;


export default ({data}) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        let { description, title, file } = node.data.target.fields
        return <img src={file["en-US"].url} />
      },
    },
  }
  return(
    <BlogPost>
      <ImageBanner>
        <Image>
          <Img fluid={data.image.fluid} />
        </Image>
      </ImageBanner>
      <TextSection>
        <Heading>
          <br/>
          <h1>{data.title}</h1>
          <h3>{data.subtitle}</h3>
          <br/>
          <hr/>
          <div className="author">By {data.author.name} - {data.author.position}</div>
          <div className="date">{data.date}</div>
          <hr/>
        </Heading>
        <Text>
          {documentToReactComponents(data.text.json, options)}
        </Text>
        </TextSection>
    </BlogPost>)
}
